// Variables
$background-color_1: black;
$background-color_2: rgb(181, 252, 94);
$background-color_3: rgb(97, 218, 251);
$background-color_4: white;
$background-color_5: #222;

$color_1: white;
$color_2: #61dafb;
$color_3: black;

$font-family_1: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-family_2: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

// Main Content
.App {
	text-align: center;
	cursor: url(svg/cursor.svg), auto;
}
.App-logo {
	height: 20vmin;
	pointer-events: none;
}
.App-header {
	background-color: $background-color_1;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: $color_1;
	a {
		color: $color_1;
	}

	&.light {
		background-color: $background-color_4;
		color: $color_3;
		a {
			color: $color_3;
		}
	}
}
.App-link {
	color: $color_2;
}

button {
	width: 32.5%;
	height: 7.5vh;
	border-radius: 20px;
	left: 40;
	background-color: $background-color_2;
	border: none;
	color: $color_3;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 1em;
	transition-duration: 0.5s;
	font-weight: bolder;
	cursor: pointer;
	&:hover {
		background-color: $background-color_3;
		box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(255,255,255,0.19);
		transition-duration: 0.5s;
	}
}

body {
	margin: 0;
	font-family: $font-family_1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	user-select: none;
}

.odometer {
	min-width: 90vw;
	font-size: 10vw;
}

small {
	bottom: 5px;
	position: fixed;
	margin: auto;
	text-decoration: none;
}

a {
	text-decoration: none;
}